<template>
  <section class="boxinside">
    <router-link :to="`/singlejob/${job.jobInformation.slug}`"
      ><h4>{{ job.jobInformation.jobPosition }}</h4></router-link
    >
    <p>
      {{ job.jobInformation.category }}
    </p>
    <p>
      {{ job.jobInformation.availableFor }}
    </p>
  </section>
</template>

<script>
export default {
  name: "Similarjob",
  props: ["job"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxinside {
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  h4 {
    color: $tagcolor;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-size: 18px;
  }
  a {
    text-decoration: none;
    font-size: $normalfont;
  }
  p {
    font-size: $normalfont;
    margin-bottom: 0;
    margin-top: 8px;
    &:last-of-type{
        background: $tagcolor;
        display: inline-block;
        padding:3px 10px;
        font-size: $midfont;
        color:$white;
        border-radius:5px;
    }
  }
}
</style>
