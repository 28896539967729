var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"singlejob"},[_c('div',{staticClass:"headerjob"}),(_vm.allloadingAd)?_c('v-container',{staticClass:"container-custom"},[_c('header',[_c('v-row',{staticClass:"margin-minus-top"},[_c('v-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"boxinside"},[_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"image","height":"200"}})],1),_c('div',{staticClass:"boxinside"},[_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"image","height":"200"}})],1),_c('div',{staticClass:"boxinside"},[_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"image","height":"200"}}),_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"image","height":"200"}})],1)]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"boxinside"},[_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"image","height":"200"}})],1),_c('v-skeleton-loader',{staticClass:"spacebtn",attrs:{"max-width":"50%","type":"image","height":"30"}}),_vm._l((5),function(n){return _c('v-skeleton-loader',{key:n + 'a',staticClass:"spacebtn",attrs:{"max-width":"100%","type":"image","height":"100"}})})],2)],1)],1)]):_vm._e(),(_vm.allSingleJob.results != undefined && !_vm.allloadingAd)?_c('v-container',{staticClass:"container-custom"},[_c('header',[_c('v-row',{staticClass:"margin-minus-top"},[_c('v-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"boxinside"},[_c('v-row',{staticClass:"reversecolm"},[(_vm.allSingleJob.results != undefined)?_c('v-col',{staticClass:"flexbox-center flexbox-center-left reverse1",attrs:{"md":"10"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.allSingleJob.results.singleJob.jobInformation.jobPosition)+" ")]),_c('h2',[_c('router-link',{attrs:{"to":'/singeCompany/' +
                      _vm.allSingleJob.results.singleCompany.baiscInfoCompany.slug}},[_vm._v(_vm._s(_vm.allSingleJob.results.singleCompany.baiscInfoCompany .companyname)+" ")])],1),_c('ul',{staticClass:"btn-actions nolist"},[(_vm.allLogin.token == undefined)?_c('li',{on:{"click":_vm.login}},[_vm._v(" Login To Apply ")]):_vm._e(),(_vm.allLogin.user != undefined && !_vm.allLogin.user.resume)?_c('li',{on:{"click":_vm.openResume}},[_vm._v(" Complete Resume To Apply ")]):_vm._e(),(
                      _vm.allLogin.user != undefined &&
                      _vm.allLogin.user.resume &&
                      !_vm.allloadingAd
                    )?_c('li',{on:{"click":_vm.applyJob}},[_vm._v(" Apply Now ")]):_vm._e(),(_vm.allloadingAd)?_c('li',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.allloadingAd)?_c('li',{on:{"click":_vm.openCV}},[_vm._v(" Apply Without Registration ")]):_vm._e()])]):_vm._e(),_c('v-col',{staticClass:"flexbox-center flexbox-center-right reverse2",attrs:{"md":"2"}},[(_vm.allSingleJob.results != undefined)?_c('div',{staticClass:"logobox"},[_c('img',{attrs:{"src":'https://app.necojobs.com.np' +
                      _vm.allSingleJob.results.singleCompany.baiscInfoCompany
                        .imageUrl,"alt":""}})]):_vm._e(),_c('ul',{staticClass:"actionbutton"},[_c('li',[_vm._v(" Share "),_c('v-icon',[_vm._v(" mdi-share-variant ")]),_c('ul',{staticClass:"socialshare"},[_c('li',[_c('ShareNetwork',{attrs:{"network":"facebook","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition,"description":_vm.allSingleJob.results.singleJob.jobSpecification
                              .careerObjectSummary,"quote":"A better training plan","hashtags":"necojobs,necojobs jobs"}},[_c('v-icon',[_vm._v(" mdi-facebook ")])],1)],1),_c('li',[_c('ShareNetwork',{attrs:{"network":"whatsapp","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition}},[_c('v-icon',[_vm._v(" mdi-whatsapp ")])],1)],1),_c('li',[_c('ShareNetwork',{attrs:{"network":"linkedin","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition}},[_c('v-icon',[_vm._v(" mdi-linkedin ")])],1)],1)])],1),_c('li',[_c('a',{attrs:{"href":"mailto:info@necojobs.com.np","target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-email ")])],1)])])])],1)],1),(_vm.allSingleJob.results != undefined)?_c('div',{staticClass:"boxinside"},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Availabilty")]),_c('span',[_vm._v(_vm._s(_vm.allSingleJob.results.singleJob.jobInformation.availableFor))])])]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Category")]),_c('span',[_vm._v(_vm._s(_vm.allSingleJob.results.singleJob.jobInformation.category))])])]),(_vm.allSingleJob.results != undefined)?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Salary Negotiable")]),(
                      _vm.allSingleJob.results.singleJob.jobInformation
                        .isSalaryNegotiable
                    )?_c('span',[_vm._v("Yes")]):_vm._e(),(
                      !_vm.allSingleJob.results.singleJob.jobInformation
                        .isSalaryNegotiable
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.allSingleJob.results.singleJob.jobInformation .minimumSalary.currency)+" "+_vm._s(_vm.allSingleJob.results.singleJob.jobInformation .minimumSalary.salaryRange)+" - "+_vm._s(_vm.allSingleJob.results.singleJob.jobInformation .maximumSalary.currency)+" "+_vm._s(_vm.allSingleJob.results.singleJob.jobInformation .maximumSalary.salaryRange)+" ")]):_vm._e()])]):_vm._e(),(_vm.allSingleJob.results != undefined)?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Job Level")]),_c('span',[_vm._v(_vm._s(_vm.allSingleJob.results.singleJob.jobInformation.jobLevel))])])]):_vm._e(),(_vm.allSingleJob.results != undefined)?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Job Location")]),_vm._l((_vm.allSingleJob.results.singleJob
                      .jobInformation.jobLocation),function(data,index){return _c('span',{key:index},[_vm._v(_vm._s(data.location))])})],2)]):_vm._e(),(_vm.allSingleJob.results != undefined)?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("No. Of Vacancy")]),_c('span',[_vm._v(_vm._s(_vm.allSingleJob.results.singleJob.jobInformation .numberOfVacancy))])])]):_vm._e(),(
                  _vm.allSingleJob.results != undefined &&
                  _vm.allSingleJob.results.singleJob.jobDescription != undefined
                )?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Education Level")]),_c('span',[_vm._v(_vm._s(_vm.allSingleJob.results.singleJob.jobDescription.education))])])]):_vm._e(),(
                  _vm.allSingleJob.results != undefined &&
                  _vm.allSingleJob.results.singleJob.jobDescription != undefined
                )?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"requirementbox"},[_c('strong',[_vm._v("Experience Required")]),_c('span',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.allSingleJob.results.singleJob.jobDescription.experience .expericeMoreLess)+" "+_vm._s(_vm.allSingleJob.results.singleJob.jobDescription.experience .expericeYear))])])]):_vm._e()],1)],1):_vm._e(),(
              _vm.allSingleJob.results != undefined &&
              _vm.allSingleJob.results.singleJob.jobDescription != undefined
            )?_c('div',{staticClass:"boxinside"},[(
                _vm.allSingleJob.results.singleJob.jobDescription.skills[0]
                  .skill != ''
              )?_c('div',{staticClass:"insidespace"},[(
                  _vm.allSingleJob.results.singleJob.jobDescription.skills
                    .length > 0
                )?_c('h4',[_vm._v(" Skills :- ")]):_vm._e(),_c('ul',{staticClass:"listview pill nolist"},_vm._l((_vm.allSingleJob.results.singleJob
                    .jobDescription.skills),function(data,index){return _c('li',{key:index + 'ski'},[_vm._v(" "+_vm._s(data.skill)+" ")])}),0)]):_vm._e(),(
                _vm.allSingleJob.results.singleJob.jobSpecification
                  .requirements != ''
              )?_c('div',{staticClass:"insidespace"},[_c('h4',[_vm._v("Requirements :-")]),_c('div',{staticClass:"texeditorcustom",domProps:{"innerHTML":_vm._s(
                  _vm.allSingleJob.results.singleJob.jobSpecification.requirements
                )}})]):_vm._e(),(
                _vm.allSingleJob.results.singleJob.jobSpecification
                  .careerObjectSummary != ''
              )?_c('div',{staticClass:"insidespace"},[_c('h4',[_vm._v("Job Responsibility :-")]),_c('div',{staticClass:"texeditorcustom",domProps:{"innerHTML":_vm._s(
                  _vm.allSingleJob.results.singleJob.jobSpecification
                    .careerObjectSummary
                )}})]):_vm._e(),(
                _vm.allSingleJob.results.singleJob.jobDescription
                  .otherSpecification &&
                _vm.allSingleJob.results.singleJob.jobDescription
                  .otherSpecification != ''
              )?_c('div',{staticClass:"insidespace"},[_c('h4',[_vm._v("Applying Procedure :-")]),_c('div',{staticClass:"texeditorcustom",domProps:{"innerHTML":_vm._s(
                  _vm.allSingleJob.results.singleJob.jobDescription
                    .otherSpecification
                )}})]):_vm._e(),(
                _vm.allSingleJob.results.singleJob.jobSpecification
                  .whoWeareLookingFor != ''
              )?_c('div',{staticClass:"insidespace"},[_c('h4',[_vm._v("Who are looking for :-")]),_c('div',{staticClass:"texeditorcustom",domProps:{"innerHTML":_vm._s(
                  _vm.allSingleJob.results.singleJob.jobSpecification
                    .whoWeareLookingFor
                )}})]):_vm._e(),(
                _vm.allSingleJob.results.singleJob.jobSpecification.benefits != ''
              )?_c('div',{staticClass:"insidespace"},[_c('h4',[_vm._v("Benefits :-")]),_c('div',{staticClass:"texeditorcustom",domProps:{"innerHTML":_vm._s(
                  _vm.allSingleJob.results.singleJob.jobSpecification.benefits
                )}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"boxinside footerbutton"},[_c('v-row',{staticClass:"reversecolm"},[(_vm.allSingleJob.results != undefined)?_c('v-col',{staticClass:"flexbox-center flexbox-center-left reverse1",attrs:{"md":"10"}},[_c('ul',{staticClass:"btn-actions nolist"},[(_vm.allLogin.token == undefined)?_c('li',{on:{"click":_vm.login}},[_vm._v(" Login To Apply ")]):_vm._e(),(_vm.allLogin.user != undefined && !_vm.allLogin.user.resume)?_c('li',{on:{"click":_vm.openResume}},[_vm._v(" Complete Resume To Apply ")]):_vm._e(),(
                      _vm.allLogin.user != undefined &&
                      _vm.allLogin.user.resume &&
                      !_vm.allloadingAd
                    )?_c('li',{on:{"click":_vm.applyJob}},[_vm._v(" Apply Now ")]):_vm._e(),(_vm.allloadingAd)?_c('li',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.allloadingAd)?_c('li',{on:{"click":_vm.openCV}},[_vm._v(" Apply Without Registration ")]):_vm._e()])]):_vm._e(),_c('v-col',{staticClass:"flexbox-center flexbox-center-right reverse2",attrs:{"md":"2"}},[_c('ul',{staticClass:"actionbutton"},[_c('li',[_vm._v(" Share "),_c('v-icon',[_vm._v(" mdi-share-variant ")]),_c('ul',{staticClass:"socialshare"},[_c('li',[_c('ShareNetwork',{attrs:{"network":"facebook","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition,"description":_vm.allSingleJob.results.singleJob.jobSpecification
                              .careerObjectSummary,"quote":"A better training plan","hashtags":"necojobs,necojobs jobs"}},[_c('v-icon',[_vm._v(" mdi-facebook ")])],1)],1),_c('li',[_c('ShareNetwork',{attrs:{"network":"whatsapp","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition}},[_c('v-icon',[_vm._v(" mdi-whatsapp ")])],1)],1),_c('li',[_c('ShareNetwork',{attrs:{"network":"linkedin","url":`https://www.necojobs.com.np/singleJob/${_vm.$route.params.slug}`,"title":_vm.allSingleJob.results.singleJob.jobInformation
                              .jobPosition}},[_c('v-icon',[_vm._v(" mdi-linkedin ")])],1)],1)])],1),_c('li',[_c('a',{attrs:{"href":"mailto:info@necojobs.com.np","target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-email ")])],1)])])])],1)],1)]),_c('v-col',{attrs:{"md":"4"}},[(_vm.allSingleJob.results != undefined)?_c('div',{staticClass:"boxinside"},[_c('h2',[_vm._v(" "+_vm._s(_vm.allSingleJob.results.singleCompany.baiscInfoCompany .companyname)+" ")]),_c('p',{staticClass:"aboutcontent",domProps:{"innerHTML":_vm._s(
                _vm.allSingleJob.results.singleCompany.baiscInfoCompany.description.slice(
                  0,
                  500
                )
              )}}),_c('small',[_c('a',{attrs:{"href":`${_vm.allSingleJob.results.singleCompany.baiscInfoCompany.website}`,"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-link ")]),_vm._v(" "+_vm._s(_vm.allSingleJob.results.singleCompany.baiscInfoCompany.website))],1)])]):_vm._e(),(_vm.allSingleJob.results)?_c('div',[_c('h4',[_vm._v("Similar Jobs")]),(_vm.allSingleJob.results.similar.length > 0)?_vm._l((_vm.allSingleJob.results.similar),function(data,index){return _c('div',{key:index},[(index < 6)?_c('Similarjob',{attrs:{"job":data}}):_vm._e()],1)}):_vm._e()],2):_vm._e()])],1)],1)]):_vm._e(),_c('div',{staticClass:"upload-cv",class:{ leftSlide: _vm.slideUpload }},[_c('ul',{staticClass:"topaction"},[_vm._m(0),_c('li',[_c('v-icon',{on:{"click":_vm.closeCV}},[_vm._v(" mdi-close-circle-outline ")])],1)]),_c('AddCv')],1),(_vm.allLogin.token != undefined)?[_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',[_vm._v(" "+_vm._s(_vm.applyforthejob.message)+" ")])])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h3',[_vm._v("Upload Your CV")])])
}]

export { render, staticRenderFns }